import Form from '../components/Form';
import EmailIcon from '../Pictures/Email.png';
import { Fragment } from 'react';
import { Helmet } from 'react-helmet';

const ContactPage = () => {
  return (
    <Fragment>
      <Helmet>
        <meta charSet='utf-8' />
        <title>Kontakt</title>
      </Helmet>
      <section>
        <h1>Kontakt</h1>
        <img src={EmailIcon} alt='Email icon' width='60' />
        <p>
          Pokud si přejete mi něco sdělit, napište mi na{' '}
          <strong>o.nekolny@centrum.cz</strong> nebo použijte následující
          formulář.
        </p>
        <Form />
      </section>
    </Fragment>
  );
};

export default ContactPage;
