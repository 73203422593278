import classes from './SkillList.module.css';
import SkillItem from './SkillItem';
import javaScriptIcon from '../Pictures/Icons/javascript_icon_256.png';
import reactIcon from '../Pictures/Icons/react_icon_256.png';
import htmlIcon from '../Pictures/Icons/html_icon_256.png';
import cssIcon from '../Pictures/Icons/css_icon_256.png';
import bootStrapIcon from '../Pictures/Icons/bootstrap_icon_256.png';
import mySqlIcon from '../Pictures/Icons/mysql_icon_256.png';
import javaIconYellow from '../Pictures/Icons/java_iconYellow_256.png';
import gitIcon from '../Pictures/Icons/git.png';

const SkillList = () => {
  const DUMMY_SKILLS_ADVANCED = [
    {
      id: 'javaIconYellow12022024',
      imageSrc: javaIconYellow,
      imageAlt: 'javaIconYellow',
      title: 'Java',
      description:
        'Java pro mě představuje největší vášeň a tvůrčí a programátorskou výzvu. Java byl můj hlavní programovací jazyk v průběhu rekvalifikačního kurzu a její síla potenciál mi dává velkou naději, že s tímto úžasným jazykem budu moci pracovat ještě dlouhou dobu i v budoucnu. Vyhovuje mi všestrannost Javy a její uplatnění v širokém množství nejrůznějších projektů.',
    },
    {
      id: 'mySql12022024',
      imageSrc: mySqlIcon,
      imageAlt: 'mySqlIcon',
      title: 'MySQL',
      description:
        'V rámci Rekvalifikačního kurzu jsem se seznámil s relačními databázemi a naučil se s nimi pracovat pomocí jazyka MySQL. Dobrou praktickou zkušeností pro fungování relačních databází je moje 1,5 roku dlouhá zkušenost s platformou ServiceNow, která je na práci s tabulkami přímo založena. Uživatele sice od nutnosti znát SQL odstiňují vlastní JavaScriptové metody pro dotazování do tabulek, které má ServiceNow implementované, nicméně i tak práce se ServiceNow pro mě představuje dobrou zkušenost práce s relačním databázovým systémem.',
    },
    {
      id: 'react12022024',
      imageSrc: reactIcon,
      imageAlt: 'reactIcon',
      title: 'React',
      description:
        'React je JavaScriptová knihovna, kterou jsem si vybral ke studiu na Udemy.com. Byla to velmi dobrá volba, která se mi osvědčila jako efektivní nástroj pro tvorbu frontendové části webových aplikací. Jako příklad stojí za zmínku, že v Reactu je napsána právě tato webová aplikace nebo demo Eshop prodávající trička. V rámci kompletního kurzu React jsem se seznámil s jednotlivými hooky, odesíláním požadavků mezi frontendem a backendem, prací s formuláři včetně jejich validace na straně klienta, Reduxem pro řízení state na úrovni celé aplikace, Tanstack Query pro optimalizování a zrychlení odesílání dat mezi frontendem a backendem, NextJS, React router domem, animovací efektů v aplikaci a okrajově také TypeScriptem a jednotkovým testováním.',
    },
    {
      id: 'javaScriptIcon12022024',
      imageSrc: javaScriptIcon,
      imageAlt: 'javaScriptIcon',
      title: 'JavaScript',
      description:
        'JavaScript je dalších z mých hlavních programovacích jazyků, na který se soustředím. Naučil jsem se ho do hloubky pomocí kurzu na Udemy.com a v rámci tohoto kurzu jsem si i vyzkoušel vytvořit cvičnou aplikaci pro zobrazování receptů pro různé pokrmy. Považuji ho za nutný základ pro psaní frontendové části webových aplikací a odrazový můstek pro používání knihoven jako je React nebo Angular.',
    },
    {
      id: 'gitIcon17092024',
      imageSrc: gitIcon,
      imageAlt: 'gitIcon',
      title: 'GIT',
      description:
        'Verzovací systém GIT používám pro pravidelné a každodenní ukládání veškeré svojí práce. Je to velmi spolehlivý nástroj a nejlepší přítel programátora.',
    },
  ];

  const DUMMY_SKILLS_BASIC = [
    {
      id: 'html12022024',
      imageSrc: htmlIcon,
      imageAlt: 'htmlIcon',
      title: 'HTML',
      description:
        'Značkovací jazyk HTML je nutným základem pro tvorbu webových aplikací i webových stránek. Vždy se snažím ho používat tak, aby HTML struktura aplikace odpovídala moderní syntaxi.',
    },
    {
      id: 'css12022024',
      imageSrc: cssIcon,
      imageAlt: 'cssIcon',
      title: 'CSS3',
      description:
        'HTML bez CSS3 je jako ryba bez vody. Žádná aplikace ani webová stránka se neobejde bez stylování. A přesně to nám zajišťuje CSS3. Umožňuje nám efektivně definovat styly naší aplikace a přitom udržet kód čistý a přehledný.',
    },
    {
      id: 'bootStrap12022024',
      imageSrc: bootStrapIcon,
      imageAlt: 'bootStrapIcon',
      title: 'BootStrap',
      description:
        'BootStrap jsem se naučil používat jako doplněk pro vlastní stylování webové aplikace pomocí CSS3. Ačkoli je BootStrap jako stylovací knihovna užitečný nástroj, já sám raději dávám přednost definici vlastních stylů.',
    },
  ];

  const skillsAdvanced = DUMMY_SKILLS_ADVANCED.map(skill => (
    <SkillItem
      key={skill.id}
      id={skill.id}
      image={{ imageSrc: skill.imageSrc, imageAlt: skill.imageAlt }}
      title={skill.title}
      description={skill.description}
    />
  ));

  const skillsBasic = DUMMY_SKILLS_BASIC.map(skill => (
    <SkillItem
      key={skill.id}
      id={skill.id}
      image={{ imageSrc: skill.imageSrc, imageAlt: skill.imageAlt }}
      title={skill.title}
      description={skill.description}
    />
  ));

  return (
    <section>
      <h1>Dovednosti</h1>
      <article id={classes.article_basic}>
        <h2>Základní</h2>
        <ul className={classes.skill_list}>{skillsBasic}</ul>
      </article>
      <article id={classes.article_main}>
        <h2>Hlavní</h2>
        <ul className={classes.skill_list}>{skillsAdvanced}</ul>
      </article>
    </section>
  );
};

export default SkillList;
