import { useRouteError } from 'react-router-dom';
import ErrorCard from '../components/ErrorCard';
import { Fragment } from 'react';
import { Helmet } from 'react-helmet';

const ErrorPage = () => {
  
  const error = useRouteError();
  let title = 'An error occured!';
  let message = 'Something went wrong!';

  if (error.status === 404) {
    title = 'Chyba 404. Stránka nenalezena!';
    message = 'Stránka, kterou jste hledali na zadané url adrese neexistuje.';
  }

  return (
    <Fragment>
      <Helmet>
        <meta charSet='utf-8' />
        <title>Stránka nenalezena</title>
      </Helmet>
      <ErrorCard title={title} message={message} />
    </Fragment>
  );
};

export default ErrorPage;
