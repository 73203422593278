import { createBrowserRouter, RouterProvider } from 'react-router-dom';

import RootLayout from './pages/RootLayout';
import HomePage from './pages/HomePage';
import AboutMePage from './pages/AboutMePage';
import SkillsPage from './pages/SkillsPage';
import ReferencesPage from './pages/ReferencesPage';
import ContactPage from './pages/ContactPage';
import ErrorPage from './pages/ErrorPage';

const router = createBrowserRouter([
  {
    path: '/',
    element: <RootLayout />,
    errorElement: <ErrorPage />,
    children: [
      {
        index: true,
        element: <HomePage />,
      },
      {
        path: 'about_me',
        element: <AboutMePage />,
      },
      {
        path: 'skills',
        element: <SkillsPage />,
      },
      {
        path: 'references',
        element: <ReferencesPage />,
      },
      {
        path: 'contact',
        element: <ContactPage />,
      },
    ],
  },
]);

function App() {
  return <RouterProvider router={router} />;
}

export default App;
