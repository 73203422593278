import SkillList from '../components/SkillList';
import { Fragment } from 'react';
import { Helmet } from 'react-helmet';

const SkillsPage = () => {
  return (
    <Fragment>
      <Helmet>
        <meta charSet='utf-8' />
        <title>Dovednosti</title>
      </Helmet>
      <SkillList />
    </Fragment>
  );
};

export default SkillsPage;
