import { Link } from 'react-router-dom';
import classes from './ErrorCard.module.css';

const ErrorCard = (props) => {
  return (
    <div className={classes.background}>
      <div className={classes.errorCard}>
        <h1>{props.title}</h1>
        {props.message}
        <Link to='/'>Zpět na domovskou stránku</Link>
      </div>
    </div>
  );
};

export default ErrorCard;
