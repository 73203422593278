import classes from './References.module.css';
import { Fragment, useState } from 'react';
import { SlideshowLightbox } from 'lightbox.js-react';
import ProjectItemsList from './ProjectItemsList.module';
import ProjectItem from './ProjectItem';
import Requalification1 from '../Pictures/Osvedceni o rekvalifikaci/Osvedceni o rekvalifikaci Ondrej Nekolny1.png';
import Requalification2 from '../Pictures/Osvedceni o rekvalifikaci/Osvedceni o rekvalifikaci Ondrej Nekolny2.png';
import CertResponsiveWebdesign from '../Pictures/Certifikat Responzivni webdesign.png';
import CertMySQL from '../Pictures/Certifikat MySQL_MariaDB databaze krok za krokem.png';
import CertJavaScript from '../Pictures/Udemy_JavaScript_Certificate.jpg';
import ReactCertificate from '../Pictures/React certificate jpg.jpg';
import JavaBasics from '../Pictures/Certifikát Základní konstrukce jazyka Java.png';
import JavaOOP from '../Pictures/Certifikát Objektově orientované programování v Javě.png';

const reactProjects = [
  {
    id: 1,
    title: '1. E-Shop',
    referrence: 'https://react-eshop-55cd6.web.app/',
  },
  {
    id: 2,
    title: '2. Video Sharing Application',
    referrence: 'https://rp-4-video-sharing-app-c7c88.firebaseapp.com/',
  },
];

const References = () => {
  const [certificatesContentSelected, setCertificatesContentSelected] =
    useState(false);
  const [projectsContentSelected, setProjectsContentSelected] = useState(false);

  const certificatesContent = (
    <Fragment>
      <article>
        <h3 className={classes.reference_certificates_h3}>
          Osvědčení o rekvalifikaci
        </h3>
        <p>
          Moje Osvědčení o rekvalifikaci je nejdůležitějším dokumentem
          dokládajícím mojí dostatečnou odbornou způsobilost pro práci v oboru
          IT.
        </p>
      </article>
      <SlideshowLightbox className={classes.reference_article}>
        <img src={Requalification1} alt="RequalificationView1" />
        <img src={Requalification2} alt="RequalificationView2" />
      </SlideshowLightbox>
      <article>
        <h3>Certifikáty</h3>
        <p>
          V této části uvádím přehled svých certifikátů, které jsem získal za
          absolvování teoretických lekcí a praktických cvičení vzdělávacích
          kurzů u akreditované společnosti ITnetwork s.r.o. a Udemy.com.
        </p>
      </article>
      <SlideshowLightbox className={classes.reference_article_certificates}>
        <img src={JavaBasics} alt="JavaBasics" />
        <img src={JavaOOP} alt="JavaOOP" />
        <img src={CertJavaScript} alt="CertJavaScript" />
        <img src={ReactCertificate} alt="ReactCertificate" />
        <img src={CertResponsiveWebdesign} alt="CertResponsiveWebdesign" />
        <img src={CertMySQL} alt="CertMySQL" />
      </SlideshowLightbox>
    </Fragment>
  );

  const projectsContent = (
    <ProjectItemsList>
      {reactProjects.map(project => {
        return (
          <ProjectItem
            key={project.id}
            title={project.title}
            referrence={project.referrence}
          />
        );
      })}
    </ProjectItemsList>
  );

  const selectCertificatesHandler = () => {
    setProjectsContentSelected(false);
    setCertificatesContentSelected(true);
  };

  const selectProjectsHandler = () => {
    setCertificatesContentSelected(false);
    setProjectsContentSelected(true);
  };

  let content = <div style={{ paddingBottom: '9.69rem' }} />;

  if (certificatesContentSelected) {
    content = certificatesContent;
  } else if (projectsContentSelected) {
    content = projectsContent;
  }

  return (
    <section>
      <article>
        <h1>Reference</h1>
        <p>
          Zde jsou informace o mojí dosavadní práci a dosaženém vzdělání. Není
          pro mě problém vytvářet webové stránky s responzivním obsahem. Rovněž
          mám praktické zkušenosti s programováním v JavaScriptu a Reactu.
        </p>
        <p>
          Prosím vyberte si zda chcete vidět informace o mých certifikátech nebo
          ukázkových projektech.
        </p>
      </article>
      <article className={classes.reference_h2}>
        <h2 onClick={selectCertificatesHandler}>Certifikáty</h2>
        <h2 onClick={selectProjectsHandler}>Moje projekty</h2>
      </article>
      <article>{content}</article>
    </section>
  );
};

export default References;
