import Home from '../components/Home';
import { Fragment } from 'react';
import { Helmet } from 'react-helmet';

const HomePage = () => {
  return (
    <Fragment>
      <Helmet>
        <meta charSet='utf-8' />
        <title>Domů</title>
      </Helmet>
      <Home />
    </Fragment>
  );
};

export default HomePage;
