import classes from "./MainNavigation.module.css";
import styles from "./commonStyles.module.css";
import { motion } from "framer-motion";

import MyNavLink from "./MyNavLink";

const MainNavigation = (props) => {
  return (
    <header>
      <nav>
        <ul className={classes.logo}>
          <li>
            <h2>
              Ondřej<span>Nekolný</span>
            </h2>
            <h5>Programátor webových aplikací</h5>
          </li>
        </ul>
        <ul className={classes.navigation}>
          <MyNavLink
            toRef="/"
            label="Domů"
            isSelected={props.selectedType === "/"}
            onSelect={() => props.onSelectType("/")}
          />
          <MyNavLink
            toRef="about_me"
            label="O mně"
            isSelected={props.selectedType === "/about_me"}
            onSelect={() => props.onSelectType("/about_me")}
          />
          <MyNavLink
            toRef="skills"
            label="Dovednosti"
            isSelected={props.selectedType === "/skills"}
            onSelect={() => props.onSelectType("/skills")}
          />
          <MyNavLink
            toRef="references"
            label="Reference"
            isSelected={props.selectedType === "/references"}
            onSelect={() => props.onSelectType("/references")}
          />
          <li>
            <motion.a
              whileHover={{ scale: 1.15 }}
              transition={{ type: "spring", stiffness: 500, mass: 100 }}
              href="contact"
              className={styles.btnReferrence}
            >
              Kontakt
            </motion.a>
          </li>
        </ul>
      </nav>
    </header>
  );
};

export default MainNavigation;
