import { Fragment } from 'react';
import { Link } from 'react-router-dom';
import { motion } from 'framer-motion';
import classes from './Home.module.css';
import styles from './commonStyles.module.css';
import Ondra_s_vousy from '../Pictures/Ondra_s_vousy.jpg';

const Home = () => {
  return (
    <Fragment>
      <section id={classes.s1} className={classes}>
        <article>
          <h1>Vítejte</h1>
          <p>
            Ahoj, vítám Vás na svém webu, jmenuji se
            <strong> Ondřej Nekolný</strong> a jsem středně pokročilým
            programátorem v jazyce Java, JavaScript a v JavaScriptové
            knihovně React. Tyto stránky slouží jako moje osobní portfolio pro
            prezentaci toho, kdo jsem a co umím.
          </p>
          <p>
            O IT obor jsem měl zájem již během dospívání. Po oklice, kdy jsem se
            věnoval studiu chemie a farmacie jsem se rozhodl opět vrátit ke své
            staré lásce, zájmu o programování! Ukázalo se to být správným rozhodnutím. Je to velmi tvůrčí práce, ve které jsem se našel. Programování jsem si zamiloval a pravidelně se v něm
            vzdělávám. Zaměřuji se na Javu a React.
          </p>
          <p>
            Mám rád kávu, přírodu a společnost. Pravidelně sportuji a není mi cizí třeba zájem o starověké civilizace.
          </p>
          <p>
            Kontaktovat mě můžete na <Link to='contact'>kontaktní stránce</Link>
            .
          </p>
        </article>
        <motion.img
          src={Ondra_s_vousy}
          alt='Ondra_s_vousy'
          initial={{ opacity: 0, scale: 0.5 }}
          animate={{ opacity: 1, scale: 1 }}
          transition={{ duration: 1 }}
        />
      </section>
      <section>
        <h2>Dovednosti</h2>
        <p>
          Dlouhodobě se věnuji práci v JavaScriptu a Reactu. K obojímu mám
          dostudované kurzy na Udemy.com. Dále práce s HTML a CSS3 pro tvorbu
          responzivních webových stránek a aplikací považuji za základ, bez
          kterého by nebylo možné efektivně pracovat s Reactem ani JavaScriptem.
          Za jádro svých znalostí nicméně považuji svoje neustále rozvíjené znalosti v jazyce Java, který je mojí největší láskou pro jeho všestrannost a široké možnosti pro jeho uplatnění. Mezi moje dovednosti patří i znalost MySQL. U SQL si cením jeho univerzálnosti při komunikaci mezi aplikací a databází. Mám za sebou 1,5 roku práce s platformou ServiceNow, která sice nekomunikuje s databázemi přímo v SQL, ale i tak jsem se zde hojně setkal s principy fungování relačních databází.
        </p>
        <p>
          <motion.a
            whileHover={{ scale: 1.15 }}
            transition={{ type: 'spring', stiffness: 500, mass: 100 }}
            href='references'
            className={styles.btnReferrence}
          >
            Moje reference
          </motion.a>
        </p>
      </section>
    </Fragment>
  );
};

export default Home;
