import classes from './ProjectItem.module.css';

const ProjectItem = props => {
  return (
    <div className={classes.column_item}>
      <a href={props.referrence} target="_blank">{props.title}</a>
    </div>
  );
};

export default ProjectItem;
