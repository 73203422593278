import { Fragment, useState, useEffect } from 'react';
import { Outlet, useLocation } from 'react-router-dom';
import MainNavigation from '../components/MainNavigation';
import Footer from '../components/Footer';

const RootLayout = () => {
  let location = useLocation();
  const [selectedType, setSelectedType] = useState(location);

  const handleSelectType = newType => {
    setSelectedType(newType);
  };

  useEffect(() => {
    setSelectedType(window.location.pathname);
  }, [location]);

  return (
    <Fragment>
      <MainNavigation
        selectedType={selectedType}
        onSelectType={handleSelectType}
      />
      <main>
        <Outlet />
      </main>
      <Footer />
    </Fragment>
  );
};

export default RootLayout;
