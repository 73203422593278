import classes from './ProjectItemsList.module.css';

const ProjectItemsList = props => {
  return (
    <div className={classes.reference_project_items_list}>
      <h3 className={classes.reference_projects_h3}>Projekty v Reactu</h3>
      {props.children}
    </div>
  );
};

export default ProjectItemsList;
