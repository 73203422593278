import classes from './SkillItem.module.css';

const SkillItem = (props) => {
  return (
    <li className={classes.list_item}>
      <img src={props.image.imageSrc} alt={props.image.imageAlt} />
      <h3>{props.title}</h3>
      <p>{props.description}</p>
    </li>
  );
};

export default SkillItem;
