import classes from './Footer.module.css';

const Footer = () => {

  return (
    <footer className={classes.footer}>
      <p>Vytvořil &copy; Ondřej Nekolný</p>
    </footer>
  );
};

export default Footer;
