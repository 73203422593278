import AboutMe from '../components/AboutMe';
import { Fragment } from 'react';
import { Helmet } from 'react-helmet';

const AboutMePage = () => {
  return (
    <Fragment>
      <Helmet>
        <meta charSet='utf-8' />
        <title>O mně</title>
      </Helmet>
      <AboutMe />
    </Fragment>
  );
};

export default AboutMePage;
