import References from '../components/References';
import { Fragment } from 'react';
import { Helmet } from 'react-helmet';

const ReferrencesPage = () => {
  return (
    <Fragment>
      <Helmet>
        <meta charSet='utf-8' />
        <title>Reference</title>
      </Helmet>
      <References />
    </Fragment>
  );
};

export default ReferrencesPage;
